// Generated by Framer (19eed98)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./yUZFMbhl8-0.js";

const cycleOrder = ["ubmHSs51X"];

const serializationHash = "framer-BPzCJ"

const variantClassNames = {ubmHSs51X: "framer-v-d4wuv5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, Twq9cv1_A: title ?? props.Twq9cv1_A ?? "Design Studio Next Gen"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Twq9cv1_A, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ubmHSs51X", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-BPzCJ", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-d4wuv5", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ubmHSs51X"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TGVhZ3VlIFNwYXJ0YW4tNzAw", "--framer-font-family": "\"League Spartan\", \"League Spartan Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-line-height": "100px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}><motion.span style={{"--framer-font-size": "89.3333px"}}>Design Studio Next Gen</motion.span></motion.p></React.Fragment>} className={"framer-3g22p9"} data-framer-name={"Nadpis"} fonts={["GF;League Spartan-700"]} layoutDependency={layoutDependency} layoutId={"Ghf8KCwle"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={Twq9cv1_A} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-BPzCJ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BPzCJ .framer-rfqbcs { display: block; }", ".framer-BPzCJ .framer-d4wuv5 { height: 235px; overflow: hidden; position: relative; width: 639px; }", ".framer-BPzCJ .framer-3g22p9 { -webkit-user-select: none; flex: none; height: auto; left: 50%; pointer-events: auto; position: absolute; top: 50%; user-select: none; white-space: pre-wrap; width: 639px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 235
 * @framerIntrinsicWidth 639
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Twq9cv1_A":"title"}
 * @framerImmutableVariables false
 */
const FrameryUZFMbhl8: React.ComponentType<Props> = withCSS(Component, css, "framer-BPzCJ") as typeof Component;
export default FrameryUZFMbhl8;

FrameryUZFMbhl8.displayName = "Nadpis";

FrameryUZFMbhl8.defaultProps = {height: 235, width: 639};

addPropertyControls(FrameryUZFMbhl8, {Twq9cv1_A: {defaultValue: "Design Studio Next Gen", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FrameryUZFMbhl8, [{family: "League Spartan", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/yUZFMbhl8:default", url: "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf", weight: "700"}])